<template>
  <div>
    <div class="pb-3">
      <!-- create button -->
      <b-button id="register-button" v-has-permission="'ORGANIZATION_CREATE_PERMISSION'" @click="add()" variant="dark">
        <b-icon-plus-circle></b-icon-plus-circle>
        <span class="ml-1">{{ $t('LIST_ADD_LABEL', {catalog: $t('ORGANIZATION')})  | capitalize }}</span>
      </b-button>
      <!-- create button -->

      <!-- refresh button -->
      <b-button class="ml-2" @click="refresh" variant="dark">
        <b-icon-arrow-clockwise></b-icon-arrow-clockwise>
        <span class="ml-1">{{ $t('LIST_REFRESH_LABEL')  | capitalize }}</span>
      </b-button>
      <!-- refresh button -->

      <!-- clear button -->
      <b-button class="ml-1" @click="clear" variant="dark">
        <b-icon-x></b-icon-x>
        <span class="ml-1">{{ $t('LIST_FILTER_CLEAR_LABEL') | capitalize }}</span>
      </b-button>
      <!-- clear button -->

      <!-- Excel export -->
      <download-excel class="ml-2 btn btn-dark"
                      :data="items"
                      :fields="excelFields"
                      :worksheet="labels.module"
                      :name="labels.excel">
        <b-icon-download class="mr-1"></b-icon-download>
        {{ $t('LIST_EXCEL_DOWNLOAD_LABEL') | capitalize }}
      </download-excel>
      <!-- Excel export -->
    </div>
    <div>
      <!-- List page filter -->
      <organization-list-filter :only-active="onlyActive" class="d-inline-block w-75" @search="setFilter"
                                v-model="filter"></organization-list-filter>
      <!-- List page filter -->

      <!-- Page size -->
      <div class="d-inline-block pl-1 w-25">
        <page-size v-model="size"></page-size>
      </div>
      <!-- Page size -->
    </div>

    <!-- table -->
    <b-table
        id="organization-table"
        ref="organizationTable"
        @row-selected="handleSelection"
        striped
        hover
        bordered
        small
        table-variant="dark"
        selectable
        :select-mode="selectMode"
        v-model="items"
        :current-page="page"
        :sort-by="sort"
        :per-page="size"
        :busy.sync="isBusy"
        :fields="fields"
        :filter="filter"
        :items="listAll">
      <template #table-busy>
        <div class="text-center text-danger my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong
              class="ml-2">{{ $t('LIST_LOADING_LABEL', {catalog: $t('ORGANIZATIONS_LABEL')})  | capitalize }}</strong>
        </div>
      </template>

      <template #head(multiselect)="">
        <input id="select-all-check" :value="true" type="checkbox" v-model="allSelected" @click="selectAll"/>
      </template>

      <template #cell(multiselect)="data">
        <input :checked="isChecked(data)" @click="toggleRow(data)" type="checkbox"/>
      </template>

      <!-- rfc -->
      <template #head(rfc)="">
        <span>{{ labels.rfc | capitalize }}</span>
      </template>

      <template #cell(rfc)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.rfc }}</span>
        </div>
      </template>
      <!-- rfc -->

      <!-- name -->
      <template #head(name)="">
        <span>{{ labels.name | capitalize }}</span>
      </template>

      <template #cell(name)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.name }}</span>
        </div>
      </template>
      <!-- name -->

      <!-- manager -->
      <template #head(manager)="">
        <span>{{ labels.manager | capitalize }}</span>
      </template>

      <template #cell(manager)="data">
        <div style="text-align: left; vertical-align: center">
          <span>{{ data.item.manager }}</span>
        </div>
      </template>
      <!-- manager -->

      <template #head(enabled)="">
        <span>{{ labels.enabled  | capitalize }}</span>
      </template>

      <template #cell(enabled)="data">
        <div style="text-align: center">
          <b-icon-check-circle variant="success" v-if="data.item.enabled"></b-icon-check-circle>
          <b-icon-x-circle variant="danger" v-else></b-icon-x-circle>
        </div>
      </template>

      <template #head(options)="">
        <span>{{ $t('LIST_OPTIONS_LABEL')  | capitalize }}</span>
      </template>

      <template #cell(options)="data">
        <div style="text-align: center">
          <b-dropdown size="sm" id="dropdown-1" right class="m-md-2">
            <b-dropdown-item v-if="hasPermission('ORGANIZATION_UPDATE_PERMISSION')" @click="edit(data.item.id)">
              {{ $t('LIST_EDIT_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'ORGANIZATION_DELETE_PERMISSION'"
                             @click="remove(data.item.id, data.item.label)">{{ $t('LIST_DELETE_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'ORGANIZATION_DISABLE_PERMISSION'"
                             @click="disable(data.item.id, data.item.label)" v-if="data.item.enabled">
              {{ $t('LIST_DISABLE_LABEL')  | capitalize }}
            </b-dropdown-item>
            <b-dropdown-item v-has-permission="'ORGANIZATION_ENABLE_PERMISSION'"
                             @click="enable(data.item.id, data.item.label)" v-else>
              {{ $t('LIST_ENABLE_LABEL')  | capitalize }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <!-- table -->
    <b-container fluid>
      <b-row>
        <b-col>
          <b-pagination
              v-model="page"
              :total-rows="totalRows"
              :per-page="size"
              pills
              aria-controls="organization-table">
          </b-pagination>
        </b-col>
        <b-col class="text-right">
          {{ (pageTotals.size ? $t('GRID_ROW_TOTAL', pageTotals) : $t('GRID_ALL_ROWS_TOTAL')) | capitalize }}
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import {Form, ProcessWithLoadingAndMessage, Table} from "@/mixins";
import organizations from "@/organization";
import eventBus from "@/events";
import OrganizationListFilter from "@/organization/OrganizationListFilter";
import PageSize from "@/components/PageSize";
import Token from "@/token";

export default {
  name: "OrganizationList",
  props: ['selectMode', 'currentlySelected', 'onlyActive'],
  components: {PageSize, OrganizationListFilter},
  mixins: [Table, Form, ProcessWithLoadingAndMessage],
  mounted() {
    eventBus.$on('LIST_COUNT_ORGANIZATION_EVENT', (totalElements) => {
      this.totalRows = totalElements;
    });
  },
  methods: {
    hasPermission(permission){
      return Token.hasPermission(permission);
    }
  },
  data() {
    return {
      labels: {
        rfc: this.$t('ORGANIZATION_RFC_LABEL'),
        name: this.$t('ORGANIZATION_NAME_LABEL'),
        manager: this.$t('ORGANIZATION_MANAGER_LABEL'),
        enabled: this.$t('LIST_ENABLED_LABEL'),
        module: this.$t('ORGANIZATIONS_LABEL'),
        excel: this.$t('ORGANIZATIONS_LABEL') + '.xls',
      },
      registerPage: 'ORGANIZATION_REGISTER_TITLE',
      editPage: 'ORGANIZATION_EDIT_TITLE',
      selectedCount: 0,
      allSelected: false,
      originals: []
    }
  },
  computed: {
    controller() {
      return organizations;
    },
    fields() {
      return [
        {key: 'multiselect', sortable: false, tdClass: 'selectTd'},
        {key: 'rfc', sortable: true, tdClass: 'customTd'},
        {key: 'name', sortable: true, tdClass: 'customTd'},
        {key: 'enabled', sortable: true, tdClass: 'enabledTd'},
        {key: 'options', sortable: false, tdClass: 'optionsTd'}];
    },
    tableRef() {
      return this.$refs.organizationTable;
    },
    excelFields() {
      const fields = {};
      fields[this.labels.rfc] = 'rfc', fields[this.labels.name] = 'name'
      return fields;
    }
  }
}
</script>

<style scoped>
.selectTd {
  width: 15px !important;
}

.nameTd {
  width: 75% !important;
}
</style>
